<ng-template #scheduleAppointmentModal>
    <div class="modal-header border-0" *ngIf="!formSuccess">
        <h5 class="modal-title pull-left">Schedule Next Session <span class="fw-normal">for</span> {{clientName}}</h5>
    </div>

    <div class="modal-body d-flex flex-column px-4">
         <!-- loader  -->
         <div class="d-flex align-items-center justify-content-center py-5" *ngIf="loadingFormDetails">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <ng-container *ngIf="!loadingFormDetails">
            <app-step-one *ngIf="currentStep == 1"></app-step-one>
            <app-step-two  *ngIf="currentStep == 2"></app-step-two>
            <app-step-three  *ngIf="currentStep == 3"></app-step-three>
        </ng-container>
    </div>
    <div class="modal-footer border-0" *ngIf="!loadingFormDetails">
        <!-- <ng-container *ngIf="!formSuccess"> -->
            <button *ngIf="currentStep == 1" type="button" class="btn btn-secondary me-auto" 
                [disabled]="modalLoader" (click)="closeModal()">
                Cancel
            </button>
            <button *ngIf="currentStep > 1"  type="button" class="btn btn-secondary me-auto" 
                [disabled]="modalLoader" (click)="prevStep()">
                Back
            </button>
            <button type="button" class="btn btn-primary" 
                [disabled]="modalLoader" (click)="nextStep()">
                Continue
            </button>
            <!-- <button type="button" class="btn btn-primary" [disabled]="modalLoader" (click)="submitForm()">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"
                    *ngIf="modalLoader"></span>
                Confirm & Schedule
            </button> -->
        <!-- </ng-container> -->
        <!-- <ng-container *ngIf="formSuccess">
            <div class="w-100 d-flex align-items-center justify-content-center">
                <button type="button" class="btn btn-primary" (click)="closeModal()">
                    Looks good!
                </button>
            </div>
        </ng-container> -->
    </div>
</ng-template>