<div class="appointment-card" [ngClass]="{
    'appointment-card-selected': currentlyDroppedLead == lead.id
  }" style="position: relative" *ngFor="let lead of leads">
  <div *ngIf="lead.consultationInfo" [ngClass]="{
      'card-refresh': showFullLoader && currentlyDroppedLead == lead.id
    }" [ngStyle]="{ opacity: showFullLoader ? '0.3' : '1' }">
    <div class="appointment-card-top" (click)="_setLeadToDrop(lead)">
      <!-- <div class="status-ring" [style.background-color]="getColorForStatus(lead.consultationInfo.status)"></div> -->

      
      <div class="time-only">
        {{ timeslotFormatter(lead.consultationInfo.timeslot) }}
        <ng-container *ngIf="lead?.isInPersonAppointment">
          <in-person-location-icon class="inperson-icon" [practiceId]="lead?.consultationInfo?.locationId" [providerProfile]="providerProfile"></in-person-location-icon>
        </ng-container>
      </div>
      <div class="f-column">
        <div class="pb-1 _patient-name">
          {{ lead.consultationInfo.patientName }}
        </div>
        <!-- color: rgb(170, 170, 170); -->
        <div class="_patient-gender">
          {{ lead.consultationInfo.patientGender | titlecase }}
        </div>
      </div>
      <div class="card-right-options">
        <div class="d-flex flex-column justify-content-end" style="margin-right: 10px;">
          <span class="_appt-status" [ngStyle]="{
              color: getColorForStatus(lead.consultationInfo.status),
              'text-align': 'right'
            }">
            {{ lead.consultationInfo.status }}
          </span>
          <span *ngIf="lead.isExpiredAppointment" style="font-size: 12px; color: #6e6e73">
            (Payment Incomplete)
          </span>
          <span *ngIf="lead.isScheduledAppointment" style="font-size: 12px; color: #6e6e73">
            (Payment Pending)
          </span>
          <span *ngIf="lead.isInPersonAppointment" style="font-size: 12px; color: #6e6e73">
            (In-Person)
          </span>
        </div>

        <!-- <button
          *ngIf="lead.consultationInfo && lead.consultationInfo.status"
          class="btn btn-outline-default"
          [ngStyle]="{
            color: getColorForStatus(lead.consultationInfo.status),
            border: '1px solid',
            'border-color': getColorForStatus(lead.consultationInfo.status)
          }"
        >
          {{ lead.consultationInfo.status }}
        </button> -->
        <div class="dropper mt-1">
          <span class="material-icons" *ngIf="currentlyDroppedLead != lead.id">
            arrow_right
          </span>
          <span class="material-icons" *ngIf="currentlyDroppedLead == lead.id">
            arrow_drop_down
          </span>
        </div>
      </div>
    </div>
    <div class="appointment-card-details" *ngIf="currentlyDroppedLead == lead.id">
      <div class="appointment-card-details-left">
        <ng-container *ngFor="let btn of apptActionsBtnsLeft | keyvalue: returnZero">
          <ng-container *ngIf="btn.value && !btn.value.disabled">
            <button class="appt-inner-btn" [ngClass]="{'text-danger' : btn?.key == 'expire_appt'}" [disabled]="loadActionButton&&loadActionButton['btnKey']==btn?.key && loadActionButton['leadId']==lead.id" (click)="leftActionButtons(btn.key, lead)">
              <span class="material-icons" style="font-size: 1em; margin-right: 0.5rem">
                {{ btn.value.icon }}
              </span>
              <span>
                {{ btn.value.label }}
              </span>
              <span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true" *ngIf="loadActionButton&&loadActionButton['btnKey']==btn?.key && loadActionButton['leadId']==lead.id"></span>
            </button>
          </ng-container>
        </ng-container>
      </div>
      <div class="appointment-card-details-middle">
        <div class="appointment-card-details-container">
          <div class="appt-detail">
            <div class="appt-detail-title">Email</div>
            <button class="appt-inner-btn" style="color: #777" (click)="viewClientEmail(lead)">
              <span> View Email </span>
            </button>
          </div>
          <div class="appt-detail">
            <div class="appt-detail-title">Phone</div>
            <button class="appt-inner-btn" style="color: #777" (click)="viewClientContactNumber(lead)">
              <span> View Contact Info </span>
            </button>
            <!-- <div class="appt-detail-desc">
              {{
                lead.consultationInfo.patientPrimaryContactNumber
                  ? "+" + lead.consultationInfo.patientPrimaryContactNumber
                  : "No Contact"
              }}
            </div> -->
          </div>
          <div class="appt-detail">
            <div class="appt-detail-title">Booking created On</div>
            <div class="appt-detail-desc">
              {{
              lead.createdOn
              ? (lead.createdOn | date: "hh:mm a, dd MMM yyyy")
              : "No timestamp"
              }}
            </div>
          </div>
        </div>
        <div class="appointment-card-details-container">
          <div class="appt-detail" *ngIf="lead.consultationInfo">
            <div class="appt-detail-title">Client UHID</div>
            <div class="appt-detail-desc">
              <div>
                {{
                loadingUser? "fetching...": user && user.uniqueID
                ? user.uniqueID
                : "-"
                }}
              </div>
            </div>
          </div>
          <div class="appt-detail" *ngIf="lead.consultationInfo">
            <div class="appt-detail-title">Fee Received</div>
            <div class="appt-detail-desc">
              <div>
                {{
                lead.netIncomingBalanceForProvider()
                ? lead.netIncomingBalanceForProvider().amount == 0? "₹ 0" : lead.netIncomingBalanceForProvider().displayVal
                : "-"
                }}
              </div>
            </div>
          </div>
          <ng-container *ngIf="lead?.isInPersonAppointment">
            <div class="appt-detail">
              <div class="appt-detail-title">Mode</div>
              <div class="appt-detail-desc">
                In-Person
              </div>
            </div>

            <div class="appt-detail">
              <div class="appt-detail-title">Location</div>
              <div class="appt-detail-desc">
                <div *ngIf="lead?.consultationInfo?.locationName">
                  {{ lead?.consultationInfo?.locationName }}
                </div>
                <div *ngIf="lead?.consultationInfo?.locationDescription">
                  {{ lead?.consultationInfo?.locationDescription }}
                </div>
                <div class="d-flex align-items-center" *ngIf="lead?.consultationInfo?.locationGoogleMapsLink">
                  <span class="c-link" (click)="openLink(lead?.consultationInfo?.locationGoogleMapsLink)">
                    View On Map
                  </span>
                  <span class="material-icons ms-2" (click)="openLink(lead?.consultationInfo?.locationGoogleMapsLink)"
                    style="font-size: 0.8em; cursor: pointer">
                    open_in_new
                  </span>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="!lead?.isInPersonAppointment">
            <div class="appt-detail" *ngIf="lead.onlineMeetingDetails">
              <div class="appt-detail-title">Platform</div>
              <div class="appt-detail-desc">
                {{ lead.getPlatformTitle() }}
              </div>
            </div>
            <div class="appt-detail">
              <div class="appt-detail-title d-flex align-items-center">
                <div>Meeting Link</div>
                <!-- <button class="appt-inner-btn btn-sm ml-2 py-0 px-3 d-flex align-items-center"
                  (click)="rightActionButtons('new_meeting', lead)" *ngIf="lead.onlineMeetingDetails">
                  <span class="material-icons" style="font-size: 1em">
                    loop
                  </span>
                </button> -->
              </div>
              <button class="btn btn-sm btn-primary mt-1" *ngIf="
                  !lead?.isExpiredAppointment && 
                  providerProfile.isGoogleAuthVerified &&
                  lead.onlineMeetingDetails == null
                " (click)="rightActionButtons('new_meeting', lead)">
                Create Google Meet Link
              </button>
              <span *ngIf="lead?.isExpiredAppointment && !lead?.onlineMeetingDetails?.url"> - </span>
              <span *ngIf="
                  !providerProfile.isGoogleAuthVerified &&
                  lead.onlineMeetingDetails == null
                ">
                To create links automatically, please go to
                <a class="text-primary" [routerLink]="['/dashboard/integrations']" routerLinkActive="router-link-active"
                  target="_blank">
                  Integrations
                </a>
                tab.
              </span>
  
              <div class="appt-detail-desc" *ngIf="lead.onlineMeetingDetails">
                <div class="mb-2" *ngIf="lead.onlineMeetingDetails.url">
                  <span class="c-link" (click)="openLink(lead.onlineMeetingDetails.url)">{{ lead.onlineMeetingDetails.url
                    | stringTrim: 37 }}</span>
                  <span class="material-icons ms-3" (click)="copyTxt(lead.onlineMeetingDetails.url)"
                    style="font-size: 0.8em; cursor: pointer">content_copy</span>
                </div>
                <div *ngIf="
                    lead.onlineMeetingDetails.id &&
                    lead.onlineMeetingDetails.platform == 'zoom'
                  ">
                  ID: {{ lead.onlineMeetingDetails.id }}
                  <span class="material-icons" (click)="copyTxt(lead.onlineMeetingDetails.id)"
                    style="font-size: 0.8em; margin-left: 4px; cursor: pointer">content_copy</span>
                </div>
                <div *ngIf="
                    lead.onlineMeetingDetails.passcode &&
                    lead.onlineMeetingDetails.platform == 'zoom'
                  ">
                  Passcode: {{ lead.onlineMeetingDetails.passcode }}
                  <span class="material-icons" (click)="copyTxt(lead.onlineMeetingDetails.passcode)"
                    style="font-size: 0.8em; margin-left: 4px; cursor: pointer">content_copy</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- <div class="appointment-card-details-right">
        <ng-container
          *ngFor="let btn of apptActionsBtnsRight | keyvalue: returnZero"
        >
          <ng-container *ngIf="btn.value.loading">
            <sa-spinner-circular
              [enabled]="btn.value.loading"
              color="#5952FF"
              secondaryColor="rgba(24, 24, 24, 0.09)"
              size="25"
              class="ml-auto mr-auto"
            ></sa-spinner-circular>
          </ng-container>
          <button
            *ngIf="btn.value && !btn.value.disabled"
            class="appt-inner-btn"
            (click)="rightActionButtons(btn.key, lead)"
          >
            <span
              class="material-icons"
              style="font-size: 1em; margin-right: 0.5rem"
            >
              {{ btn.value.icon }}
            </span>
            <span>
              {{ btn.value.label }}
            </span>
          </button> -->
      <!-- <button
            *ngIf="lead.onlineMeetingDetails && lead.onlineMeetingDetails.url"
            class="appt-inner-btn"
            (click)="openLink(lead.onlineMeetingDetails.url)"
          >
            <span
              class="material-icons"
              style="font-size: 1em; margin-right: 0.5rem"
            >
              meeting_room
            </span>
            <span> Join Meeting </span>
          </button> -->
      <!-- </ng-container>
      </div> -->
    </div>
  </div>
</div>

<schedule-appointment-modal #scheduleModal></schedule-appointment-modal>
<schedule-session-modal #scheduleSessionModal></schedule-session-modal>

