import { Injectable } from '@angular/core';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { IScheduleSessionForm } from '../interfaces/IScheduleSessionForm';
import { Subscription } from 'rxjs';
import { ProviderDataService } from 'src/app/shared/services/provider-service/provider-data.service';
import { AppointmentType, ServiceTypes } from 'src/app/shared/models-ts/Enums/Enums';
import { SchSessionPaymentTerms } from 'src/app/shared/models-ts/Entities/SchSession/SchSession';
import { CountrywiseFeeCalc, Currency } from 'src/app/shared/models-ts/Helpers/countrywiseFeeCalc';
import { DayjsHelper } from 'src/app/shared/models-ts/Helpers/dayjsHelper';

@Injectable()
export class ScheduleSessionDataService {
  //Data Variables
  providerProfile: DoctorListingProfile = new DoctorListingProfile();
  clientId: string;
  clientName: string;
  scheduleSessionform: IScheduleSessionForm = null;
  subscription: Subscription;
  formSuccess: boolean = false;

  //UI & Interfacing Variables
  modalLoader: boolean = false;
  sessionTypeOptions = Object.keys(ServiceTypes);
  modeOptions: AppointmentType[] = [];
  paymentTermOptions = Object.keys(SchSessionPaymentTerms);
  currencyOptions = Object.keys(Currency);
  amountOptions = [];
  loadingFormDetails: boolean = false;
  currentStep = 1;//1,2,3,4

  //Helpers
  dayjsHelper = new DayjsHelper();
  dayjs = this.dayjsHelper.getDayjs();

  constructor(
    private _providerDataService: ProviderDataService
  ) { }

  setProfileSub() {
    this.subscription = this._providerDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['provider']) {
          this.providerProfile = data['provider'];
          this.modeOptions = [AppointmentType.online];
          if(this.providerProfile.isAcceptingInPersonBookingsOnDocVita()) {
            this.modeOptions.push(AppointmentType.inPerson);
          }
          this.standardNatInNatPricing();
        } else {
          //error manage
        }
      },
      (err) => {
        //error manage
      }
    );
  }

  standardNatInNatPricing() {
    this.amountOptions = [];
    if (
      this.providerProfile &&
      this.providerProfile.onlineConsultationConfig &&
      this.providerProfile.onlineConsultationConfig.fee != null
    ) {
      const masterSpecialization =
        this.providerProfile.getMasterSpecialization();
      const fee = this.providerProfile.onlineConsultationConfig.fee;
      const in_fees = new CountrywiseFeeCalc().fee(
        fee,
        'IN',
        masterSpecialization
      );
      const us_fees = new CountrywiseFeeCalc().fee(
        fee,
        'US',
        masterSpecialization
      );
      const ae_fees = new CountrywiseFeeCalc().fee(
        fee,
        'AE',
        masterSpecialization
      );
      const othr_fees = new CountrywiseFeeCalc().fee(
        fee,
        null,
        masterSpecialization
      );
      // let fees_string = '(IN) ' + in_fees.amount + ' ' + in_fees.unit + ' • (US/CA) ' + us_fees.amount + ' ' + us_fees.unit + ' • (Other) ' + othr_fees.amount + ' ' + othr_fees.unit
      this.amountOptions = [in_fees, us_fees, ae_fees, othr_fees];
    }
  }

  initSessionForm() {
    this.formSuccess = false;
    this.scheduleSessionform = {
      amount: null,
      // amount: 20,
      currency: Currency.INR,
      duration: this.providerProfile?.appointmentDuration,
      inviteTime: '',
      // inviteTime: '16:00',
      inviteDate: this.dayjsHelper.addDaysToYYYYMMDD(
        this.dayjsHelper.todayYYYYMMDD(),
        7
      ),
      clientId: this.clientId,
      providerId: this.providerProfile?.id,
      paymentTerms: SchSessionPaymentTerms.within_48_hours,
      // expiresOn: new Date().getTime(),
      expiresOn: null,
      sessionType: ServiceTypes.individual,
      type: AppointmentType.online,
      practiceId: null,
      clientName: this.clientName,
      providerName: this.providerProfile?.name,
      updatedById: this.providerProfile?.id,
      updatedByName: this.providerProfile?.name,
    };
    
    // this.setExpiryTime();
    // this.showAvailableTimeslots = true;
    // this.allowTimeEdit = false;
  }

  setMode() {
    if(this.scheduleSessionform.type == AppointmentType.inPerson) {
      this.scheduleSessionform.practiceId = this.providerProfile.associatedPractices.keys().next().value;
    } else {
      this.scheduleSessionform.practiceId = null;
    }
  }

  nextStep() {
    this.currentStep++;
    return true;
  }

  prevStep() {
    if(this.currentStep > 1) {
      this.currentStep--;
      return true;
    } else {
      return false;
    }
  }


  onDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
