<ng-container>
  <div class="top-section">
    <!-- <button class="back-btn" (click)="gotoMenu()">
            <span class="material-icons back-btn-icon">
            arrow_back_ios
            </span>
        </button> -->
    <div class="route-label">Help & Support</div>
  </div>
  <div class="main-wrapper">
    <div class="card wip-container">
      <img
        src=".././../../../assets/doctors.svg"
        alt="coming soon"
        class="wip-icon mb-4"
      />
      <div>
        <div class="mb-2">For any help feel free to reach out to our care team on </div>
        <div class="mb-1">
          Care Team Timings: <span style="color:#343a40">09:00 AM - 11:30 PM IST, Mon-Sun</span>
        </div>
        <div class="mb-1">
          WhatsApp: <a class="pointer" target="_blank" href="https://wa.me/919535273027">+91-9535273027</a>
        </div>
        <div class="mb-1">
          Email: <a class="pointer" target="_blank" href="mailto:hello@docvita.in">hello@docvita.in</a>
        </div>
      </div>
      <!-- <span class="material-icons wip-icon">
                engineering
            </span>
            <div class="wip-text">
                New updates..Coming soon!
            </div> -->
      <!-- <img
        src=".././../../../assets/soon_illstrn.svg"
        alt="coming soon"
        class="wip-icon"
      />
      <div style="display: flex; flex-direction: column">
        <div class="coming-soon-txt">Coming Soon!</div>
        <div class="wip-text">The app is still in the making. Stay tuned!</div>
      </div> -->
    </div>
  </div>
</ng-container>
