import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AppointmentType } from 'src/app/shared/models-ts/Enums/Enums';
import { ScheduleSessionDataService } from '../../services/schedule-session-data.service';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit, AfterViewInit {

  modeOptions:AppointmentType[] = [];
  
  constructor(
    public _scheduleSessionDataService: ScheduleSessionDataService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.modeOptions = this._scheduleSessionDataService.modeOptions;
  }

  getModeTitle(mode: AppointmentType) {
    switch(mode) {
      case AppointmentType.inPerson:
          return "In-person";
      case AppointmentType.online:
          return "Online";
      default:
          return mode;
    }
  }

}
