import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import { zoomIcon, googleIcon } from 'src/app/shared/common-assets';
import * as dayjs from 'dayjs';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProviderService } from 'src/app/shared/services/provider-service/provider.service';
import { ClipboardService } from 'src/app/shared/services/clipboard/clipboard.service';
import { ToastrService } from 'ngx-toastr';
import { RescheduleRequestModalComponent } from 'src/app/components/reschedule-request-modal/reschedule-request-modal.component';
import { MeetingLinkModalComponent } from 'src/app/components/meeting-link-modal/meeting-link-modal.component';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { User } from 'src/app/shared/models-ts/Actors/User/User';
import { MeetingLinkHelper } from 'src/app/components/meeting-link-modal/meeting-link-helper/meeting-link-helper';
import { ClientInfoEnum, ViewClientInfoModalComponent } from '../view-client-info-modal/view-client-info-modal.component';
import { AppointmentRequestSlackTriggerHelper } from './helpers/AppointmentRequestSlackTriggerHelper';
import { CancelRequestModalComponent } from 'src/app/components/cancel-request-modal/cancel-request-modal.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PatientsService } from 'src/app/services/patients.service';
import { ScheduleAppointmentModalComponent } from 'src/app/components/schedule-appointment-modal/schedule-appointment-modal.component';
import { SchSessionService } from 'src/app/shared/services/sch-session/sch-session.service';
import { ScheduleSessionModalComponent } from 'src/app/schedule-session/components/schedule-session-modal/schedule-session-modal.component';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
})
export class AppointmentDetailsComponent implements OnInit {
  @Input() leadDate: string;
  _leads: Lead[] = [];
  @Input() set leads (val: Lead[]) {
    this._leads = val;
  }
  get leads(): Lead[] {
    return this._leads;
  }
  @Input() currentlyDroppedLead: string;

  _providerProfile: DoctorListingProfile;
  @Input() set providerProfile(val: DoctorListingProfile){
    if(val && val.id){
      this._providerProfile = val;
      this.apptActionsBtnsLeft.schedule_appt.label += this._providerProfile?.sessionOrAppointment(true);
      this.leftActionButtonController();
    }else{
      this._providerProfile = null;
    }
  };
  get providerProfile(): DoctorListingProfile {
    return this._providerProfile;
  }
  
  @Output() populateAppointments: EventEmitter<any> = new EventEmitter<any>();
  @Output() setLeadToDrop: EventEmitter<string> = new EventEmitter<string>();
  selectedLead: Lead = new Lead();
  apptActionsBtnsLeft = {
    completed: {
      icon: 'done_all',
      label: 'Mark as Completed',
      disabled: true,
      loading: false,
    },
    not_completed: {
      icon: 'undo',
      label: 'Incomplete',
      disabled: true,
      loading: false,
    },
    confirm: {
      icon: 'thumb_up_off_alt',
      label: 'Confirm',
      disabled: true,
      loading: false,
    },
    schedule_appt: {
      icon: 'event',
      label: 'Schedule Next ' ,
      disabled: true,
      loading: false,
    },
    // schedule_appt_1: {
    //   icon: 'event',
    //   label: 'Schedule 1 Next ' ,
    //   disabled: true,
    //   loading: false,
    // },
    expire_appt: {
      icon: 'event',
      label: 'Expire Scheduled Invite',
      disabled: true,
      loading: false,
    },
  };
  loadActionButton: {btnKey:string,leadId:string};
  apptActionsBtnsRight = {
    new_meeting: { icon: 'link', label: 'New Meeting', disabled: false, loading: false },
  };
  meetingLinkOpts = {
    googlemeet: { src: googleIcon, label: 'Meet Link' },
    enable_googlemeet: { src: googleIcon, label: 'Authorize Meets' },
  };
  appointment_status_map = {
    CONFIRMED: 'Confirmed',
    CANCELLED: 'Cancelled',
    PENDING: 'Pending',
    COMPLETED: 'Completed',
  };
  creatingLink: boolean = false;
  linkCreated: boolean = false;
  linkToDisplay: string;
  showFullLoader: boolean = false;
  bsModalRef: BsModalRef<ViewClientInfoModalComponent>;
  user: User;
  loadingUser: boolean;
  @ViewChild('scheduleModal') scheduleModal:ScheduleAppointmentModalComponent;
  @ViewChild('scheduleSessionModal') scheduleSessionModal:ScheduleSessionModalComponent;
  constructor(
    private _toastr: ToastrService,
    private _clipboardService: ClipboardService,
    private _providerService: ProviderService,
    private modalService: BsModalService,
    private _aptService: AppointmentService,
    private _analyticsService:AnalyticsService,
    private _patientsService: PatientsService,
    private _schSessionsService:SchSessionService,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
  }
  _setLeadToDrop(lead: Lead) {
    if (this.currentlyDroppedLead == lead.id) {
      this.setLeadToDrop.emit('');
      this.selectedLead = new Lead();
    } else {
      this.setLeadToDrop.emit(lead.id);
      this.selectedLead = lead;
      this.leftActionButtonController();
      this.rightActionButtonController();
      this.fetchUser();
    }

  }

  async fetchUser() {
    try {
      setTimeout(async ()=>{
        const userDocvitaId = this.user?.docvitaId;
        const selectedPatientId = this.selectedLead?.consultationInfo.patientId;
        if(!userDocvitaId || !selectedPatientId || userDocvitaId != selectedPatientId) this.user = null;
        this.loadingUser = true;
        const obj = await this._patientsService.fetchClientProfileFromDB(this.selectedLead?.consultationInfo.patientId);
        if(obj.docvitaId == this.selectedLead.consultationInfo?.patientId) {
          this.user = obj;
        }
        this.loadingUser = false;
        this.cdf.detectChanges();
      }, 110);
    } catch(error) {
      this.loadingUser = false;
    }
  }
  
  leftActionButtonController() {
    this.apptActionsBtnsLeft.confirm.disabled = true;
    this.apptActionsBtnsLeft.completed.disabled = true;
    this.apptActionsBtnsLeft.not_completed.disabled = true;
    this.apptActionsBtnsLeft.schedule_appt.disabled = false;
    // this.apptActionsBtnsLeft.schedule_appt_1.disabled = false;
    this.apptActionsBtnsLeft.expire_appt.disabled = true;

    if(!this.selectedLead?.consultationInfo?.status){
      return;      
    }

    switch(this.selectedLead?.consultationInfo?.status){
      case 'PENDING': {
        this.apptActionsBtnsLeft.confirm.disabled = false;
        break;
      } 
      case 'CONFIRMED': {
        this.apptActionsBtnsLeft.completed.disabled = false;
        break;
      }
      case 'COMPLETED': {
        this.apptActionsBtnsLeft.not_completed.disabled = false;
        break;
      }
      case 'SCHEDULED': {
        this.apptActionsBtnsLeft.expire_appt.disabled = false;
        break;
      }
    }
  }

  rightActionButtonController() {}

  getColorForStatus(status: string) {
    let color = '';
    switch (status) {
      case 'CONFIRMED':
        color = '#5952ff';
        break;
      case 'CANCELLED':
        color = '#ff6767';
        break;
      case 'PENDING':
        color = '#faab2d';
        break;
      case 'COMPLETED':
        color = '#21bf21';
        break;

      default:
        break;
    }
    return color;
  }
  async leftActionButtons(key: string, lead: Lead) {
    this.selectedLead = lead;
    try {
      switch (key) {
        case 'completed': {
          let jsonVal = {
            status: 'COMPLETED',
          };
          this.apptActionsBtnsLeft.completed.loading = true;
          this.showFullLoader = true;
          let updateJson = await this._aptService.updateConsultationJSON(
            lead.id,
            jsonVal
          );
          if (updateJson) {
            this.selectedLead = new Lead();
            // this.currentlyDroppedLead = '';
            this.setLeadToDrop.emit('');
            this.populateAppointments.emit();
            this._toastr.success('Marked as completed!');
          } else {
            this._toastr.error('Action Failed!');
          }
          this.apptActionsBtnsLeft.completed.loading = false;
          this.showFullLoader = false;
          break;
        }

        // case 'pending': {
        //   let jsonVal = {
        //     status: 'PENDING',
        //   };
        //   this.apptActionsBtnsLeft.pending.loading = true;
        //   let updateJson = await this._aptService.updateConsultationJSON(
        //     lead.id,
        //     jsonVal
        //   );
        //   if (updateJson) {
        //     this.selectedLead = new Lead();
        //     this.currentlyDroppedLead = '';
        //     this.populateAppointments();
        //     this._toastr.success('Marked as pending!');
        //   } else {
        //     this._toastr.error('Action Failed!');
        //   }
        //   this.apptActionsBtnsLeft.pending.loading = false;
        //   break;
        // }
        case 'confirm': {
          let jsonVal = {
            status: 'CONFIRMED',
          };
          this.showFullLoader = true;
          this.apptActionsBtnsLeft.confirm.loading = true;
          let updateJson = await this._aptService.updateConsultationJSON(
            lead.id,
            jsonVal
          );
          if (updateJson) {
            this.selectedLead = new Lead();
            // this.currentlyDroppedLead = '';
            this.setLeadToDrop.emit('');
            this.populateAppointments.emit();
            this._toastr.success('Marked as confirmed!');
          } else {
            this._toastr.error('Action Failed!');
          }
          this.apptActionsBtnsLeft.confirm.loading = false;
          this.showFullLoader = false;
          break;
        }
        case 'not_completed': {
          let jsonVal = {
            status: 'CONFIRMED',
          };
          this.apptActionsBtnsLeft.not_completed.loading = true;
          this.showFullLoader = true;
          let updateJson = await this._aptService.updateConsultationJSON(
            lead.id,
            jsonVal
          );
          if (updateJson) {
            this.selectedLead = new Lead();
            // this.currentlyDroppedLead = '';
            this.setLeadToDrop.emit('');
            this.populateAppointments.emit();
            this._toastr.success('Marked as confirmed!');
          } else {
            this._toastr.error('Action Failed!');
          }
          this.apptActionsBtnsLeft.not_completed.loading = false;
          this.showFullLoader = false;
          break;
        }
        // case 'cancel': {
        //   let slackMessage = new AppointmentRequestSlackTriggerHelper().getSlackMessagebodyForCancellation(lead, this.providerProfile);
        //   this.apptActionsBtnsLeft.cancel.loading = true;
        //   this.showFullLoader = true;
        //   let sendTrigger =
        //     await this._aptService.sendSlackWhatsappChannelTrigger(
        //       slackMessage
        //     );
        //   if (sendTrigger) {
        //     this.modalService.show(CancelRequestModalComponent);
        //   } else {
        //     this._toastr.error('Failed to send request.');
        //   }
        //   this.apptActionsBtnsLeft.cancel.loading = false;
        //   this.showFullLoader = false;
        //   break;
        // }
        // case 'reschedule': {
        //   let slackMessage = new AppointmentRequestSlackTriggerHelper().getSlackMessagebodyForReschedule(lead, this.providerProfile);
        //   this.apptActionsBtnsLeft.reschedule.loading = true;
        //   this.showFullLoader = true;
        //   let sendTrigger =
        //     await this._aptService.sendSlackWhatsappChannelTrigger(
        //       slackMessage
        //     );
        //   if (sendTrigger) {
        //     this.modalService.show(RescheduleRequestModalComponent);
        //   } else {
        //     this._toastr.error('Failed to send request.');
        //   }
        //   this.apptActionsBtnsLeft.reschedule.loading = false;
        //   this.showFullLoader = false;
        //   break;
        // }
        case 'schedule_appt': {
          this.scheduleModal.openModal(lead?.consultationInfo?.patientId,lead?.consultationInfo?.patientName, lead?.id);
          break;
        }
        case 'schedule_appt_1': {
          this.scheduleSessionModal.openModal(lead?.consultationInfo?.patientId,lead?.consultationInfo?.patientName, lead?.id);
          break;
        }
        case 'expire_appt': {
          this.expireScheduledSession();
          break;
        }
        default:
          break;
      }
      
    } catch (error) {
      console.log(error);
      this._toastr.error('Action Failed!');
    }
  }

  rightActionButtons(key: string, lead: Lead) {
    this.selectedLead = lead;
    switch (key) {
      case 'new_meeting': {
        // console.log(this.selectedLead.consultationInfo);
        this.resetLinkMakerState();
        this.openMeetingLinkPopUp();
        break;
      }
      // case 'meeting_info': {
      //   this.copyMeetingInfo(lead);
      //   break;
      // }
      // case 'join_meeting': {
      //   this.openLink(lead.onlineMeetingDetails.url);
      //   break;
      // }

      default:
        break;
    }
  }

  async openMeetingLinkPopUp() {
    this.showFullLoader = true;
    this.apptActionsBtnsRight.new_meeting.loading = true;
    this.apptActionsBtnsRight.new_meeting.disabled = true;
    let link: string = await new MeetingLinkHelper().meetingLinkButtonActions(
      "googlemeet",this.selectedLead,
      this._aptService,this._toastr,this._providerService);
    this.apptActionsBtnsRight.new_meeting.loading = false;
    this.apptActionsBtnsRight.new_meeting.disabled = false;
    this.showFullLoader = false;
    /// This will be commented for now because we have only one platform
    // this.modalService.show(MeetingLinkModalComponent, {
    //   initialState: {
    //     providerProfile: this.providerProfile,
    //     lead: this.selectedLead,
    //   },
    // });
  }
  resetLinkMakerState() {
    this.linkCreated = false;
    this.linkToDisplay = '';
  }
  async meetingLinkButtonActions(key: string) {
    try {
      switch (key) {
        case 'googlemeet': {
          if (this.selectedLead && this.selectedLead.id) {
            this.creatingLink = true;
            let meetlink = await this._aptService.createGoogleMeeting(
              this.selectedLead
            );
            if (meetlink) {
              this.linkToDisplay = meetlink;
              this.creatingLink = false;
              this._toastr.success('Meeting Info Updated!');
              // this.meetingPopup.close();
              // this.linkCreated = true;
            } else {
              this.creatingLink = false;
              // this.meetingPopup.close();
              // this.linkCreated = false;
              this._toastr.warning('Failed to create link');
            }
          } else {
            this._toastr.warning('Something went wrong. Try again.');
          }
          break;
        }

        case 'zoom': {
          if (this.selectedLead && this.selectedLead.id) {
            this.creatingLink = true;
            let zoomlink = await this._aptService.createZoomMeeting(
              this.selectedLead
            );
            if (zoomlink) {
              this.linkToDisplay = zoomlink;
              this.creatingLink = false;
              this._toastr.success('Meeting Info Updated!');
              // this.meetingPopup.close();
              // this.linkCreated = true;
            } else {
              this.creatingLink = false;
              // this.meetingPopup.close();
              // this.linkCreated = false;
              this._toastr.warning('Failed to create link');
            }
          } else {
            this._toastr.warning('Something went wrong. Try again.');
          }
          break;
        }

        case 'enable_googlemeet': {
          let meetUrl = await this._providerService.getGoogleMeetAuthUrl(
            this.providerProfile.id
          );
          if (meetUrl) window.open(meetUrl, '_self');
          // this._router.navigate(['dashboard/integrations']);
          break;
        }

        case 'enable_zoom': {
          // this._router.navigate(['dashboard/integrations']);
          let meetUrl = await this._providerService.getZoomAuthUrl(
            this.providerProfile.id
          );
          if (meetUrl) window.open(meetUrl, '_self');
          break;
        }

        default:
          break;
      }
    } catch (error) {}
  }
  toEnable(key: string) {
    let bool = true;
    switch (key) {
      case 'googlemeet':
        if (!this.providerProfile.isGoogleAuthVerified) {
          bool = false;
        }
        break;
      case 'zoom':
        if (!this.providerProfile.isZoomAuthVerified) {
          bool = false;
        }
        break;
      case 'enable_googlemeet':
        if (this.providerProfile.isGoogleAuthVerified) {
          bool = false;
        }
        break;
      case 'enable_zoom':
        if (this.providerProfile.isZoomAuthVerified) {
          bool = false;
        }
        break;

      default:
        break;
    }
    return bool;
  }
  copyTheLink() {
    if (this.linkToDisplay) {
      this.copyTxt(this.linkToDisplay);
    }
  }
  copyTxt(val: string) {
    this._clipboardService.copyToClipboard(val);
  }
  openLink(link: string) {
    if (link) window.open(link, '_new');
  }

  copyMeetingInfo(lead: Lead) {
    if (
      this.providerProfile &&
      this.providerProfile.id &&
      lead &&
      lead.consultationInfo &&
      lead.onlineMeetingDetails
    ) {
      let gender =
        lead.consultationInfo && lead.consultationInfo.patientGender
          ? lead.consultationInfo.patientGender
          : null;
      let salutation: string;
      if (gender) {
        salutation = gender.toLowerCase() == 'male' ? 'sir' : "ma'am";
      } else {
        salutation = "sir/ma'am";
      }
      let platform = lead.getPlatformTitle();
      let message = `For your ${
        this.providerProfile.isPsychologist ? 'session' : 'consultation'
      } with ${this.providerProfile.getFirstNameWithSalutation()}, kindly use the following link to join the ${platform} call.\n\n`;
      message +=
        'Time: ' +
        lead.consultationInfo.getStartTimeIn12HoursFormat() +
        ' ' +
        lead.consultationInfo.getDisplayDayAppointmentConfirmation();
      // let message = `For your consultation with ${this.providerProfile.fullName()} at ${dateTime}, kindly use the following link to join the ${platform} call.\n\n`;
      if (lead.onlineMeetingDetails.url) {
        message += '\nLink: ' + lead.onlineMeetingDetails.url;
      }
      if (lead.onlineMeetingDetails.platform == 'zoom') {
        if (lead.onlineMeetingDetails.id)
          message += `\n\nMeeting ID: ${lead.onlineMeetingDetails.id}`;
        if (lead.onlineMeetingDetails.passcode)
          message += `\nPasscode: ${lead.onlineMeetingDetails.passcode}`;
      }
      if (lead.onlineMeetingDetails.platform == 'googlemeet') {
        let url = lead.onlineMeetingDetails.url;
        let code = url.includes('meet.google.com/')
          ? url.split('meet.google.com/')[1].substring(0, 12)
          : null;
        if (code)
          message += `\nor open Google Meet and enter the code: ${code}\n`;
      }
      this.copyTxt(message);
    }
  }
  timeslotFormatter(timeslot) {
    if (timeslot) {
      let _s = this.tConvert(timeslot.split('-')[0]);
      let _e = this.tConvert(timeslot.split('-')[1]);
      return _s + ' - ' + _e;
    } else {
      return '';
    }
  }
  tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' am' : ' pm';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }
  returnZero() {
    return 0;
  }

  viewClientEmail(lead:Lead) {
    this.viewClientInfo(ClientInfoEnum.email,lead);
    this._analyticsService.viewClientInfo(lead.consultationInfo.doctorId, lead.id, lead.consultationInfo.patientId, ClientInfoEnum.email); 
  }

  viewClientContactNumber(lead:Lead) {
    this._analyticsService.viewClientInfo(lead.consultationInfo.doctorId, lead.id, lead.consultationInfo.patientId, ClientInfoEnum.contactNumber);
    this.viewClientInfo(ClientInfoEnum.contactNumber,lead);
  }

  viewClientInfo(type: ClientInfoEnum, lead:Lead) {
    this.bsModalRef = this.modalService.show(ViewClientInfoModalComponent);
    this.bsModalRef.content.type = type;
    this.bsModalRef.content.lead = lead;
  }


  async expireScheduledSession(){
    try {
      const selectedLeadId = this.selectedLead?.id.slice();
      this.loadActionButton = {
        btnKey:'expire_appt',
        leadId: selectedLeadId
      }
      const response = await this._schSessionsService.expireScheduledSession(this.selectedLead?.consultationInfo?.appointmentId)
      this._toastr.success('Expired scheduled session!');
      this.loadActionButton = null;
    } catch (error) {
      this._toastr.error('Failed to expire scheduled session');
      this.loadActionButton = null; 
    }
  }

  
}
