import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleSessionModalComponent } from './components/schedule-session-modal/schedule-session-modal.component';
import { ScheduleSessionDataService } from './services/schedule-session-data.service';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepOneComponent } from './components/step-one/step-one.component';
import { StepTwoComponent } from './components/step-two/step-two.component';
import { StepThreeComponent } from './components/step-three/step-three.component';

@NgModule({
  declarations: [
    ScheduleSessionModalComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule
  ],
  exports:[ScheduleSessionModalComponent],
  providers: [ScheduleSessionDataService, BsModalService, BsModalRef]
})
export class ScheduleSessionModule { }
