import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ScheduleSessionDataService } from '../../services/schedule-session-data.service';
import { SchSessionService } from 'src/app/shared/services/sch-session/sch-session.service';

@Component({
  selector: 'schedule-session-modal',
  templateUrl: './schedule-session-modal.component.html',
  styleUrls: ['./schedule-session-modal.component.scss']
})
export class ScheduleSessionModalComponent implements OnInit, OnDestroy {
  clientName: string;
  @ViewChild('scheduleAppointmentModal') scheduleAppointmentModal;
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private _scheduleSessionDataService: ScheduleSessionDataService,
    private _schSessionService: SchSessionService,
    private _ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    this._scheduleSessionDataService.setProfileSub();
  }

  openModal(clientId: string, clientName: string, currentLeadId: string) {
    if (!clientId || !clientName) {
      return;
    }
    this._scheduleSessionDataService.clientId = clientId;
    this._scheduleSessionDataService.clientName = clientName;
    this.clientName = this._scheduleSessionDataService.clientName;
    this._scheduleSessionDataService.initSessionForm();
    this.initFormFieldsFromOnlineConfig(currentLeadId);
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(this.scheduleAppointmentModal, modalOptions);
  }

  async initFormFieldsFromOnlineConfig(defaultLeadId: string) {
    this._ngZone.run(async () => {
      try {
        this._scheduleSessionDataService.loadingFormDetails = true;
        const formDetails =
          await this._schSessionService.getSchSessionFormDetails(
            defaultLeadId,
            this._scheduleSessionDataService.providerProfile?.id,
            this._scheduleSessionDataService.clientId
          );
        this._scheduleSessionDataService.scheduleSessionform.amount = formDetails.amount;
        this._scheduleSessionDataService.scheduleSessionform.currency = formDetails.currency;
        this._scheduleSessionDataService.scheduleSessionform.duration = formDetails.duration;
        this._scheduleSessionDataService.scheduleSessionform.sessionType = formDetails.sessionType;
        this._scheduleSessionDataService.scheduleSessionform.type = formDetails.type;
        this._scheduleSessionDataService.loadingFormDetails = false;
        this._scheduleSessionDataService.setMode();
      } catch (error) {
        //do nothing
        this._scheduleSessionDataService.loadingFormDetails = false;
      }
    });
  }

  nextStep() {
    const bool = this._scheduleSessionDataService.nextStep();
    if(bool) {
      //Success
    } else {
      //Error
    }
  }

  prevStep() {
    const bool = this._scheduleSessionDataService.prevStep();
    if(bool) {
      //Success
    } else {
      //Error
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this._scheduleSessionDataService.onDestroy();
  }

  get currentStep() {
    return this._scheduleSessionDataService.currentStep;
  }

  get loadingFormDetails() {
    return this._scheduleSessionDataService.loadingFormDetails;
  }


}
