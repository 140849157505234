import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ProviderDataService } from 'src/app/shared/services/provider-service/provider-data.service';
import { SigninService } from 'src/app/shared/services/signin-service/signin.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ChatAppService } from 'src/app/services/chat-app/chat-app.service';
class DashboardOption {
  icon: string;
  label: string;
  route: string;
  notifCount?: number;
  constructor(icon: string, label: string, route: string) {
    this.icon = icon;
    this.label = label;
    this.route = route;
  }
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class MenuComponent implements OnInit, OnDestroy {
  dashBoardOptions = {};
  dropDownOptions = {};
  providerProfile: DoctorListingProfile = new DoctorListingProfile();
  subscription: Subscription;
  selectedOption: string;
  dropDown: boolean = false;

  constructor(
    private _providerDataService: ProviderDataService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _signinService: SigninService,
    private _eref: ElementRef,
    private _analyticsService: AnalyticsService,
    private _chatAppService: ChatAppService
  ) { }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target))
      // or some similar check
      // doSomething();
      this.dropDown = false;
  }

  ngOnInit(): void {
    this.populateDashboardOptions();
    this.checkRouter();
    this._router.events.subscribe(() => {
      // console.log(window.location.href)
      this.checkRouter();
    });

    this.setProfileSub();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setProfileSub() {
    this.subscription = this._providerDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['provider']) {
          this.providerProfile = data['provider'];
          this.populateDashboardOptions();
          // Below functions Commented this for now, till we launch the messaging feature
          // this.checkAndLoadChatInMenu(); 
          // this.getTotalUnreadCountForProvider();
        } else {
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  populateDashboardOptions() {
    this.dashBoardOptions = {
      // 'home':new DashboardOption('home','Dashboard','home'),
      appointments: new DashboardOption(
        'medication',
        'Appointments',
        'appointments',

      ),
      availability: new DashboardOption(
        'clock',
        'Availability',
        'availability',

      ),
      // payouts: new DashboardOption(
      //   'account_tree',
      //   'Payouts',
      //   'payouts',

      // ),
      // 'settings':new DashboardOption('settings','Settings','settings'),
    };
    this.dropDownOptions = {
      integrations: new DashboardOption(
        'account_tree',
        'Integrations',
        'integrations',

      ),
      support: new DashboardOption(
        'contact_support',
        'Help & Support',
        'support',

      ),
      // faqs: new DashboardOption('chat', 'FAQs', '#',),
      // whats_new: new DashboardOption('rss_feed', "What's New", '#',),
    };
    // this.dashBoardBottomOptions = {};
  }


  gotoRoute(route: string) {
    if (route && route == "messages") {
      window.open(window.location.origin + "/messages", "_self");
    } else if (route && route != '#') {
      this._router
        .navigate([route])
        .then(() => { })
        .catch((err) => { });
    }
  }

  checkRouter() {
    let currentUrl = window.location.href.toString();
    if (currentUrl) {
      let params = currentUrl.split('/');
      // console.log(params)
      let child = params[3];
      if (child && child.includes('?')) {
        child = child.split('?')[0];
      }
      if (
        (child && this.dashBoardOptions[child]) ||
        this.dropDownOptions[child]
      ) {
        this.setOption(child);
      } else {
        this.setOption('appointments');
      }
    }
  }

  setOption(option: string) {
    this.selectedOption = option;
  }

  returnZero() {
    return 0;
  }

  toggleDropDown() {
    this.dropDown = !this.dropDown;
  }

  getContactNumber() {
    if (this.providerProfile.primaryContactNumber) {
      let contact = this.providerProfile.primaryContactNumber;
      return '+' + contact.countryCode + '-' + contact.number;
    } else {
      return '+xxxxxx';
    }
  }

  async logout() {
    try {
      let providerId: string = this.providerProfile.id;
      let state = await this._signinService.signOut();
      if (state) {
        this._analyticsService.logout(providerId);
        this._router.navigate(['']);
        this._providerDataService.updateCurrentDoctor(null);
      } else {
        console.log('Failed to signout');
      }
    } catch (error) {
      console.log('Failed to signout');
    }
  }

  get hasChatModuleAccess() {
    return this.providerProfile?.hasChatModuleAccess?.bool
  }

  /**
   * Not in use for now, until we launch messaging features.
   */
  checkAndLoadChatInMenu() {
    if (this.hasChatModuleAccess) {
      this.dashBoardOptions['chat'] = new DashboardOption(
        'chat',
        'Messages',
        'messages',
      )
    } else if (this.dashBoardOptions['chat']) {
      delete this.dashBoardOptions['chat'];
    } else {
      // do nothing
    }
  }
  /**
    * Not in use for now, until we launch messaging features.
    */
  async getTotalUnreadCountForProvider() {
    try {
      const unreadCount = await this._chatAppService.getTotalUnreadCountForProvider(this.providerProfile?.id)
      // this.navTabs[1]['unreadCount'] = 0;
      if (this.dashBoardOptions['chat'] && unreadCount) {
        this.dashBoardOptions['chat']['notifCount'] = unreadCount;
      }

    } catch (error) {
      this.dashBoardOptions['chat']['notifCount'] = 0;
      // console.log(error);
    }
  }


}
