import { Expose } from "class-transformer";

export class SourceTracking {

    //Facebook Ads
    @Expose() utm_source: string;
    @Expose() utm_medium: string;
    @Expose() utm_content: string;
    @Expose() fbclid: string;
	@Expose() ad_id: string;

    //Google Ads
    @Expose() source: string;
    @Expose() gclid: string;
    @Expose() gbraid: string;
    @Expose() gad_source: string;
    @Expose() campaignid: string;
    @Expose() keyword: string;
    @Expose() device: string;
    @Expose() adgroupid: string;
    @Expose() adid: string;
    @Expose() extension: string;
    @Expose() position: string;
    @Expose() match: string;
    @Expose() network: string;
    @Expose() src: string;

    //Client Details
    @Expose() patientId: string;
    @Expose() email: string;
    @Expose() countryCode: string;
    @Expose() number: string;
    @Expose() updatedOn: number;
    @Expose() createdOn: number;
}