<div>
    <h6>Session Mode</h6>
    <div class="form-check d-flex" *ngFor="let mode of modeOptions; let i = index">
        <input class="form-check-input" type="radio" name="sessionMode" id="{{mode}}" value="{{mode}}" [(ngModel)]="_scheduleSessionDataService.scheduleSessionform.type" [value]="mode">
        <label class="ms-2 form-check-label" for="{{mode}}">
            {{getModeTitle(mode)}}
        </label>
    </div>
    
    <h6>Selected Date & Time</h6>
    <div>
        //Date time picker will come here
    </div>
</div>